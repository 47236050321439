<template>
    <div class="row">
        <div class="col-lg-8">
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                        <h3 class="card-label">Kegiatan</h3>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-6" v-for="kegiatan in kegiatan" :key="kegiatan.id">
                        <!--begin::Content-->
                        <div class="d-flex align-items-center flex-grow-1">
                            <!--begin::Checkbox-->
                            <!-- <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                                <input type="checkbox" value="1" />
                                <span></span>
                            </label> -->
                            <!--end::Checkbox-->
                            <!--begin::Section-->
                            <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                                <!--begin::Info-->
                                <div class="d-flex flex-column align-items-cente py-2 w-75">
                                    <!--begin::Title-->
                                    <span class="text-muted font-weight-bold">{{ kegiatan.tanggal_mulai | dateToString }}  s.d. {{ kegiatan.tanggal_selesai | dateToString }} </span>
                                    <!--end::Title-->
                                    <!--begin::Data-->
                                    <a href="#" class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{{kegiatan.agenda}}</a>
                                    <!--end::Data-->
                                </div>
                                <!--end::Info-->
                                <!--begin::Label-->
                                <div v-if="kegiatan.status_sistem === 'Buka'">
                                    <span class="label label-lg label-light-success label-inline font-weight-bold py-4">{{kegiatan.status_sistem}}</span>
                                </div>
                                <div v-else-if="kegiatan.status_sistem === 'Tutup Sementara'">
                                    <span class="label label-lg label-light-warning label-inline font-weight-bold py-4">{{kegiatan.status_sistem}}</span>
                                </div>
                                <div v-else>
                                    <span class="label label-lg label-light-danger label-inline font-weight-bold py-4">{{kegiatan.status_sistem}}</span>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--end::Section-->
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
    name: "jadwalKegiatan",
    data() {
        return {
            url: localStorage.getItem('baseapi'),
            token: localStorage.getItem('id_token'),
            kegiatan:[],
            tahun: new Date().getFullYear(),
            profil:[],
            pm:[],
            pmppb:[],
            vf:[],
            vfppb:[],
            surveyor:[],
            ppk:[],
            hipmi:[],
            user: JSON.parse(localStorage.getItem('user')),
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Jadwal Kegiatan" }]);
        this.loaddata()
    },
    filters:{
        dateToString(value){
            let temp = value.split("-");
            let month = parseInt(temp[1]) - 1;
            const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            return temp[2] + " " + monthNames[month] + " " + temp[0];
        }
    },
    methods: {
        format_date(value){
            if (value) {
            return moment(String(value)).format('DD-MM-YYYY')
            }
        },
        loaddata(){
            axios.get(this.url+"/tanggal_kegiatan", {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.kegiatan = response.data.data;
            }).catch(error=>{
                Swal.fire({
                    title: error.response.data,
                    text: "",
                    icon: 'error',
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$store
                            .dispatch(LOGOUT)
                            .then(() => this.$router.push({ name: "login" }));
                        }
                    })
                return error
                });
        }
    },
}
</script>